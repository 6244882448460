import React from 'react';
import { sBottomLinks, sOuter, sGrid } from '@style/style';
import { ButtonBorder } from '@components/Elements';
import Headline from '@components/Headline/Headline';
import { Grid } from '@material-ui/core';
import { Profile } from '@/EZC/pages/account/Account.page';
import SettingsPersonal from './components/SettingsPersonal';
import SettingsAvatar from './components/SettingsAvatar';
import SettingsDeleteAccount from './components/SettingsDeleteAccount';
import SettingsPush from './components/SettingsPush';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useAuthService } from '@/services/AuthService';
import { useSettingsPageQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAccountCoins } from '@/EZC/hooks/useAccountCoins';
import { SettingsAddressBook } from '@/EZC/domains/Account/organisms/SettingsAddressBook/SettingsAddressBook';

const Settings = () => {
    const { actualBalance } = useAccountCoins();
    const { data, loading, error } = useSettingsPageQuery({
        onError: graphqlErrorHandler(true),
    });
    const userType = useAuthService((state) => state.user?.userType);

    if (loading) return <div />;
    if (error) {
        return null;
    }
    if (!data) return <p>Error</p>;

    const { personal, email, settings } = data?.user || {};

    const isAdmin = userType === 'Admin';
    const isRestaurateur = userType === 'Restaurateur';

    return (
        <React.Fragment>
            <TopNav
                title={`Ustawienia`}
                subtitle={personal && `- ${personal.name} ${personal.surname}`}
            />
            <div css={sOuter}>
                <Headline>Ustawienia</Headline>
                <Grid container css={sGrid}>
                    <Profile
                        actualBalance={actualBalance}
                        personal={personal || {}}
                        isRestaurateur={isRestaurateur}
                        isAdmin={isAdmin}
                    />
                    <SettingsPersonal
                        email={email}
                        userPersonal={personal || {}}
                    />
                    <SettingsAddressBook />
                    <SettingsAvatar avatar={personal?.avatarUrl} />
                    <SettingsPush
                        pushNotifications={settings?.pushNotifications}
                    />
                    <SettingsDeleteAccount data={data} />
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
                        <ButtonBorder link={'/privacy'}>
                            Polityka Prywatności
                        </ButtonBorder>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default Settings;
