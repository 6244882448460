import React from 'react';
import { Grid } from '@material-ui/core';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { ButtonBorder } from '../../../components/Elements';
import { useCarouselTabsQuery } from '@graphql/generated';
import ProdCarousel from './views/ProdCarousel/ProdCarousel.view';
import RestCarousel from './views/RestCarousel/RestCarousel.view';
import { sOuter, sGrid, sBottomLinks } from '../../../style/style';
import { MEAL_TIME } from '@constants/fromGraphql/MealTime.constants';
import { DISH } from '@constants/fromGraphql/Dish.constants';
import { DIET } from '@constants/fromGraphql/Diet.constants';
import { CUISINE } from '@constants/fromGraphql/Cuisine.constants';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import PageLoader from '@components/PageLoader/PageLoader';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { HomeFilterHeader } from '@/EZC/domains/Home/molecules/HomeFilterHeader/HomeFilterHeader';
import { globals } from '@style/index';
import { mediaQueries } from '@style/breakpoints';
import eatzonLogo from '@style/assets/eatzon';
import { ButtonBorder as ButtonBorderV2 } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useAuthService } from '@/services/AuthService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useHistory } from 'react-router-dom';
import { useTestModeService } from '@/services/TestModeService/useTestModeService';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useUserSettingsStoreShallow } from '@/EZC/services/UserSettingsStore';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';

const EzcIndex = () => {
    const { top } = useSafeAreaInsets();
    const {
        applyUserPreferences,
        showBrochures,
        distance,
        consumptionTypes,
        geolocation,
    } = useUserSettingsStoreShallow([
        'applyUserPreferences',
        'showBrochures',
        'distance',
        'consumptionTypes',
        'geolocation',
    ]);
    const { localizedBannerEnabled, restaurantsForLocalizedHomepage } =
        useServerConfigStoreShallow([
            'restaurantsForLocalizedHomepage',
            'localizedBannerEnabled',
        ]);

    const repeatedPayload = {
        applyUserPreferences,
        isBrochure: showBrochures,
        maxDistance: (parseInt(distance ?? '') || 0) * 1000 || undefined,
        location: {
            coordinates: [geolocation.lng, geolocation.lat],
        },
        consumptionTypes,
    };

    const { data, loading } = useCarouselTabsQuery({
        variables: {
            breakfastInput: {
                payload: {
                    preferableMealTimes: ['BREAKFAST'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            deserInput: {
                payload: {
                    preferableDishes: ['DESERT'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            dinnerInput: {
                payload: {
                    preferableMealTimes: ['DINNER'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            supperInput: {
                payload: {
                    preferableMealTimes: ['SUPPER'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            mainInput: {
                payload: {
                    preferableDishes: ['MAIN'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            appetizerInput: {
                payload: {
                    preferableDishes: ['APPETIZER'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            saladInput: {
                payload: {
                    preferableDishes: ['SALAD'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            pizzaInput: {
                payload: {
                    preferableDishes: ['PIZZA'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            pastaInput: {
                payload: {
                    preferableDishes: ['PASTA'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            burgerInput: {
                payload: {
                    preferableDishes: ['BURGER'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            steakInput: {
                payload: {
                    preferableDishes: ['STEAK'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            sandwichInput: {
                payload: {
                    preferableDishes: ['SANDWICH'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            meatInput: {
                payload: {
                    preferableDiets: ['MEAT'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            veganInput: {
                payload: {
                    preferableDiets: ['VEGAN'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            vegetarianInput: {
                payload: {
                    preferableDiets: ['VEGETARIAN'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            polishInput: {
                payload: {
                    preferableCuisines: ['POLISH'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            italianInput: {
                payload: {
                    preferableCuisines: ['ITALIAN'],
                    ...repeatedPayload,
                },
            },
            americanInput: {
                payload: {
                    preferableCuisines: ['AMERICAN'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            europeanInput: {
                payload: {
                    preferableCuisines: ['EUROPEAN'],
                    ...repeatedPayload,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
        },
        onError: graphqlErrorHandler(true),
    });

    const history = useHistory();

    const { handleClick } = useTestModeService();

    const { isLoggedIn } = useAuthService((state) => ({
        isLoggedIn: state.isLoggedIn,
    }));

    const waiterView = useWaiterService((state) => state.waiterView);

    const loginPath = waiterView ? '/waiter' : '/login';

    const renderFilteredCarousels = (keys, preferable, category) => {
        const filteredTabs = [];

        if (data) {
            const carouselData = {};

            keys.map((key) => {
                const dataKey = key.toLowerCase();
                carouselData[key] = data[dataKey];
            });

            for (const key in carouselData) {
                if (carouselData[key]?.metadata?.totalCount > 0) {
                    filteredTabs.push({
                        _id: key.toUpperCase(),
                        name:
                            category[key.toUpperCase()].pluralName ??
                            category[key.toUpperCase()].name,
                    });
                }
            }
        }

        return !!filteredTabs.length ? (
            <ProdCarousel tabs={filteredTabs} payloadKey={preferable} />
        ) : null;
    };

    if (loading) return <PageLoader />;

    return (
        <>
            <TopNav disable />
            <HomeFilterHeaderWrapper container paddingTop={top}>
                {!isLoggedIn && (
                    <Grid item xs={12}>
                        <LogoWrapper>
                            <div onClick={handleClick}>{eatzonLogo}</div>
                            <ButtonBorderV2
                                onClick={() => history.push(loginPath)}
                                variant="gray"
                            >
                                {isLoggedIn ? 'Wyloguj' : 'Zaloguj'}
                            </ButtonBorderV2>
                        </LogoWrapper>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <HomeFilterHeader />
                </Grid>
            </HomeFilterHeaderWrapper>
            <RestCarousel />
            <React.Fragment>
                {/* // TODO EAT-546 uncomment component when query will be ready */}
                {/* <RecommendationProductCarousel /> */}
                {renderFilteredCarousels(
                    ['MAIN', 'CREAM', 'DESERT', 'APPETIZER', 'SALAD'],
                    'preferableDishes',
                    DISH,
                )}
                {renderFilteredCarousels(
                    ['PIZZA', 'PASTA', 'BURGER', 'STEAK', 'SANDWICH'],
                    'preferableDishes',
                    DISH,
                )}
                {renderFilteredCarousels(
                    ['MEAT', 'VEGAN', 'VEGETARIAN'],
                    'preferableDiets',
                    DIET,
                )}
                {renderFilteredCarousels(
                    ['POLISH', 'ITALIAN', 'AMERICAN', 'EUROPEAN'],
                    'preferableCuisines',
                    CUISINE,
                )}
                {renderFilteredCarousels(
                    ['BREAKFAST', 'DINNER', 'SUPPER'],
                    'preferableMealTimes',
                    MEAL_TIME,
                )}
            </React.Fragment>
            <div css={sOuter}>
                <Grid container css={sGrid}>
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
                        <ButtonBorder link={'/privacy'}>
                            Polityka Prywatności
                        </ButtonBorder>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const HomeFilterHeaderWrapper = styled(Grid)(
    ({ paddingTop }) => css`
        padding: 24px 24px;
        position: relative;
        z-index: 1;
        width: 100vw;
        max-width: ${globals.maxWidthMedium}px;
        margin: 0 auto;

        ${mediaQueries.sm} {
            padding: ${paddingTop + 24}px 24px 24px;
        }
    `,
);

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;

    ${mediaQueries.sm} {
        padding: 0 0 24px;
    }

    svg {
        height: 32px;
        width: auto;
    }
`;

export default EzcIndex;
