import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom';
import Headline from '../../../../components/Headline/Headline';
import { sOuter, sGrid, sBottomLinks } from '../../../../style/style';
import { colors, s, alpha } from '../../../../style';
import { ButtonBorder } from '../../../../components/Elements';
import { Grid } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import AccountBalanceIcon from '@material-ui/icons/DoneAll';
import WalletTransactions from './WalletTransactions';
import mastercardLogo from '../../../../style/assets/mastercardlogo';
import visaLogo from '../../../../style/assets/visalogo';
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed';
import { useWalletPageQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAccountCoins } from '@/EZC/hooks/useAccountCoins';
import { WalletPaymentRequests } from '@/EZC/pages/account/wallet/WalletPaymentRequests';
import { WalletCharge } from '@/EZC/domains/Account/organisms/WalletCharge/WalletCharge';
import styled from '@emotion/styled';
import { mediaQueriesMaterial } from '@style/breakpoints';
import usePayment from '@/EZC/views/Payment/hooks/usePayment';
import { useUnsettledPayoffs } from '@/EZC/hooks/useUnsettledPayoffs';
import { useHistory } from 'react-router-dom';

const Wallet = () => {
    const { actualBalance, blockedBalance } = useAccountCoins();
    const { data, loading, error } = useWalletPageQuery({
        onError: graphqlErrorHandler(true),
    });
    const { isUnsettledPayoff } = useUnsettledPayoffs();
    const history = useHistory();
    const { amount, isLoading, onValueChange, onSubmit } = usePayment({
        mobileTransactionFinishedCallback: () =>
            history.push('/account/transactions'),
    });

    if (loading) return <div />;
    if (error) {
        return null;
    }

    const { personal } = data.user || {};

    return (
        <React.Fragment>
            <TopNav
                title={'Portfel'}
                subtitle={personal && `- ${personal.name} ${personal.surname}`}
            />
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <ButtonBorder link={`/account/transactions`}>
                            Transakcje
                        </ButtonBorder>
                    }
                >
                    Portfel
                </Headline>
                <Grid container css={[sGrid]} justifyContent="space-between">
                    <Tile
                        subtitle={'Dostępne Środki'}
                        version="BALANCE"
                        coins={actualBalance}
                        linkDisabled
                    />
                    <Tile
                        subtitle={'Blokady'}
                        version="BLOCK"
                        coins={blockedBalance}
                        linkDisabled
                    />
                    <AccordionContainer item xs={12} md={6}>
                        <WalletCharge
                            expanded
                            collapsedPadding="0"
                            amount={amount}
                            isLoading={isLoading}
                            onValueChange={onValueChange}
                            onSubmit={onSubmit}
                            title="Wpłata"
                            description="Jedna wpłata, wiele zamówień! Wygodne opłacanie prosto z portfela - szybsze płatności zawsze pod ręką."
                        />
                    </AccordionContainer>
                    <WalletTransactions />
                    <WalletPaymentRequests showBadge={isUnsettledPayoff} />
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
                        <ButtonBorder link={'/privacy'}>
                            Polityka Prywatności
                        </ButtonBorder>
                    </Grid>
                    <Grid item xs={12} css={sLogos}>
                        <div>{mastercardLogo}</div>
                        <div>{visaLogo}</div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export const Tile = ({ coins, subtitle, version, linkDisabled }) => {
    return (
        <Grid item xs={12} md={6}>
            <Link
                to={`/account/wallet`}
                css={[
                    sTileContainer,
                    version === 'BALANCE' && sTileBalance,
                    version === 'BLOCK' && sTileBlock,
                    { display: 'flex', flexDirection: 'column' },
                    linkDisabled && { pointerEvents: 'none' },
                ]}
            >
                <div className="balance-title">
                    {coins[0]}.<span>{coins[1]}</span>&nbsp;
                    <span css={{ fontSize: '22px', fontWeight: 800 }}>zł</span>
                </div>
                <div className="balance-subtitle">{subtitle}</div>
                <div className="balance-icon">
                    {version === 'BALANCE' && (
                        <AccountBalanceIcon
                            className="balance-icon-free"
                            fontSize="inherit"
                        />
                    )}
                    {version === 'BLOCK' && (
                        <BlockIcon
                            className="balance-icon-block"
                            fontSize="inherit"
                        />
                    )}
                </div>
            </Link>
        </Grid>
    );
};

const AccordionContainer = styled(Grid)`
    padding: 0 12px 24px 0;

    ${mediaQueriesMaterial.sm} {
        padding: 0 0 24px 0;
    }
`;

const sTileContainer = {
    position: 'relative',
    overflow: 'hidden',
    fontWeight: 700,
    backgroundColor: colors.gray200,
    padding: '1rem',
    borderRadius: '1rem',
    marginBottom: '1rem',
    [s.md]: {
        padding: '2rem',
        marginBottom: '1.5rem',
    },
    '.balance-title': {
        fontSize: '30px',
        marginBottom: '1rem',
        [s.md]: { marginBottom: '1.5rem' },
        span: {
            opacity: 0.3,
        },
    },
    '.balance-subtitle': {
        fontSize: '13px',
    },
    '.balance-icon': {
        opacity: 0.3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translate(-25%, -50%)',
        fontSize: '4rem',
        [s.md]: { transform: 'translate(-40%, -50%)' },
    },
    '.balance-icon-free': {},
    '.balance-icon-block': {},
};

const sTileBalance = {
    background: `linear-gradient(to right, ${colors.green.concat(
        alpha[8],
    )}, ${colors.green.concat(alpha[30])})`,
    color: colors.green,
    [s.md]: { marginRight: '0.75rem' },
};

const sTileBlock = {
    background: `linear-gradient(to right, ${colors.red.concat(
        alpha[8],
    )}, ${colors.red.concat(alpha[30])})`,
    color: colors.red,
    [s.md]: { marginLeft: '0.75rem' },
};

const sLogos = {
    margin: '1rem 0 0',
    paddingTop: '2rem',
    borderTop: `1px solid ${colors.text.concat(alpha[16])}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 0,
    [s.md]: { margin: '1.5rem 0 0', paddingTop: '2.5rem' },
    svg: {
        height: 48,
        width: 'auto',
    },
};

export default Wallet;
