import React, { useState } from 'react';
import { ButtonBorder } from '@components/Elements';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { sGrid, sOuter, sOuterPadding } from '@style/style';
import { sRestSkeleton, sRestSkeletonInner } from '@/EZW/pages/Home/HomePage';
import RestContainer from '@/EZC/views/ProductPage/components/RestContainer';
import { colors, s } from '@style';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { MenuItem } from '@/EZC/pages/account/Account.page';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Grid } from '@material-ui/core';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import PersonIcon from '@material-ui/icons/Person';
import { RestItemLarge } from '@/EZC/components/RestItem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { useHome } from './useHome';
import { useAuthService } from '@/services/AuthService';
import { client } from '@/boot/client';
import { RestaurateurPrivacyPolicyPopup } from '@/EZR/components/organisms/RestaurateurPrivacyPolicyPopup/RestaurateurPrivacyPolicyPopup';
import { sentry } from '@/services/SentryService';
import styled from '@emotion/styled';

const itemsData = [
    {
        to: '/rest/restaurant',
        name: 'Restauracja',
        description: 'Zarządzaj aktywnym lokalem',
        AvatarIcon: RestaurantIcon,
    },
    {
        to: '/rest/restaurant/new',
        name: 'Nowa Restauracja',
        description: 'Dodaj kolejny lokal',
        AvatarIcon: AddLocationIcon,
    },
    {
        to: '/rest/products',
        name: 'Produkty',
        description: 'Modyfikuj istniejące produkty',
        AvatarIcon: FastfoodIcon,
    },
    {
        to: '/rest/product/new',
        name: 'Nowy Produkt',
        description: 'Dodaj kolejny produkt',
        AvatarIcon: AddBoxIcon,
    },
    {
        to: '/rest/menu',
        name: 'Menu',
        description: 'Zarządzaj kartą menu',
        AvatarIcon: MenuBookIcon,
    },
    {
        to: '/rest/payments',
        name: 'Transakcje',
        description: 'Sprawdź ostatnie płatności',
        AvatarIcon: AccountBalanceWalletIcon,
    },
    {
        to: '/rest/access',
        name: 'Dostęp',
        description: 'Zarzadzaj kontem kelnera',
        AvatarIcon: SupervisorAccountIcon,
    },
    {
        to: '/waiter',
        name: 'Panel Kelnera',
        AvatarIcon: RoomServiceIcon,
    },
    {
        to: '/',
        name: 'Panel Klienta',
        AvatarIcon: PersonIcon,
    },
];

const HomePage = () => {
    const {
        logout,
        restaurantId,
        setRestaurantId,
        restaurateurPrivacyPolicyPopup,
    } = useAuthService((state) => state);
    const { restaurantsSorted, dataRestaurant } = useHome();

    const scrollTop = () => {
        if (typeof document !== 'undefined') {
            document
                .getElementById('scroller')
                .childNodes[0].scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <React.Fragment>
            <TopNav title={'Restaurator'} offset={60} />
            <div css={sOuterPadding}>
                <Headline
                    itemChildren={
                        <ButtonBorder action={logout}>Wyloguj</ButtonBorder>
                    }
                >
                    Restaurator
                </Headline>
            </div>
            {restaurantId ? (
                dataRestaurant ? (
                    <RestContainerWithSaldo dataRestaurant={dataRestaurant} />
                ) : (
                    <div css={sRestSkeleton}>
                        <div css={sRestSkeletonInner} />
                    </div>
                )
            ) : null}
            <div css={[sOuter, sOuter2]}>
                <Grid container css={sGrid}>
                    {restaurantId ? (
                        itemsData.map((item, id) => (
                            <MenuItem key={id} id={id} {...item} />
                        ))
                    ) : (
                        <>
                            <MenuItem
                                to="/rest/restaurant/new"
                                description="Dodaj lokal"
                                name="Nowa Restauracja"
                                AvatarIcon={AddLocationIcon}
                            />
                            <MenuItem
                                to="/"
                                name="Panel Klienta"
                                AvatarIcon={PersonIcon}
                            />
                        </>
                    )}
                    <MenuItem
                        name="Wyloguj"
                        AvatarIcon={MeetingRoomIcon}
                        action={() => logout(client, sentry)}
                    />
                </Grid>
            </div>
            <div css={[sOuter, sOuter2]}>
                <Headline
                    itemChildren={
                        <ButtonBorder link={'/rest/restaurant/new'}>
                            Nowa Restauracja
                        </ButtonBorder>
                    }
                >
                    Restauracje
                </Headline>
                <Grid container css={sGrid}>
                    {restaurantsSorted?.map((restaurant, id) => (
                        <Grid
                            onClick={() => {
                                setRestaurantId(restaurant.id);
                                scrollTop();
                            }}
                            key={id}
                            item
                            xs={12}
                            md={6}
                            css={[
                                {
                                    paddingBottom: '1.5rem',
                                    cursor: 'pointer',
                                },
                            ]}
                        >
                            <RestItemLarge
                                id={id + 2}
                                data={restaurant}
                                restView
                                restViewActive={
                                    dataRestaurant?.restaurant?.id ===
                                    restaurant.id
                                }
                            />
                        </Grid>
                    ))}
                    <ButtonsWrapper item xs={12}>
                        <ButtonBorder link={'/terms-restaurateur'}>
                            Regulamin
                        </ButtonBorder>
                        <ButtonBorder link={'/privacy'}>
                            Polityka Prywatności
                        </ButtonBorder>
                    </ButtonsWrapper>
                </Grid>
            </div>
            {!restaurateurPrivacyPolicyPopup && (
                <RestaurateurPrivacyPolicyPopup />
            )}
        </React.Fragment>
    );
};

export const RestContainerWithSaldo = ({ dataRestaurant, saldoChildren }) => {
    return (
        <div
            css={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <RestContainer
                data={dataRestaurant?.restaurant}
                extraCss={{ [s.md]: { marginBottom: 0 } }}
            >
                <div
                    css={[
                        sBalance,
                        {
                            position: 'absolute',
                            top: '3rem',
                            right: '2rem',
                            width: 'max-content',
                            zIndex: 300,
                            fontSize: '23px',
                            padding: '1.5rem 2rem',
                            [s.sm_down]: { display: 'none' },
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        },
                    ]}
                >
                    <span>Saldo</span>
                    {dataRestaurant?.restaurant?.coins?.toFixed(2)} ZŁ
                </div>
                <div
                    css={[
                        {
                            position: 'absolute',
                            bottom: '3rem',
                            right: '3.5rem',
                            zIndex: 300,
                            display: 'flex',
                            [s.sm_down]: { display: 'none' },
                        },
                    ]}
                >
                    <ButtonBorder
                        extraCss={sButtonBorder}
                        link={`/restaurant/${dataRestaurant?.restaurant?.slug}`}
                    >
                        <VisibilityIcon fontSize="inherit" />
                        &nbsp;&nbsp;Wyświetl
                    </ButtonBorder>
                    <div css={{ paddingLeft: '1.5rem' }} />
                    <ButtonBorder
                        extraCss={sButtonBorder}
                        link={`/rest/restaurant/`}
                    >
                        <EditIcon fontSize="inherit" />
                        &nbsp;&nbsp;Edytuj
                    </ButtonBorder>
                </div>
            </RestContainer>
            <div
                css={[
                    sBalance,
                    {
                        [s.md]: {
                            display: 'none',
                        },
                    },
                ]}
            >
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Saldo</span>
                    {dataRestaurant?.restaurant?.coins?.toFixed(2)} ZŁ
                </div>
                {saldoChildren}
            </div>
        </div>
    );
};

const ButtonsWrapper = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    ${s.sm_down} {
        padding: 16px 0;
    }

    ${s.md} {
        padding: 24px 0;
    }
`;

const sButtonBorder = {
    backgroundColor: colors.gray200,
    color: colors.text,
    boxShadow: '0px 0px 0px black',
};

const sBalance = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'calc(100vw - 3rem)',
    backgroundColor: colors.gray200,
    padding: '1.5rem',
    fontSize: '18px',
    fontWeight: 700,
    margin: '0 1.5rem',
    borderRadius: '1rem',
    letterSpacing: '0.1em',
    span: {
        fontSize: '12px',
        textTransform: 'uppercase',
        color: colors.gray600,
        letterSpacing: '0.2em',
        marginBottom: '0.25rem',
    },
};

const sOuter2 = { marginTop: '2rem', [s.sm]: { marginTop: '2rem' } };

export default HomePage;
